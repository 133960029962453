<template>
  <v-dialog
    v-model="alwaysOpen"
    width="80%"
    max-width="512px"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card class="form-dialog form-dialog--new-user">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>
      <h1 class="form-dialog__title">
        {{ title }}
      </h1>
      <p class="form-dialog__subtitle">
        {{ $t('text.adding_admin') }}
      </p>

      <v-form
        ref="form"
        class="edit-admin-form"
        @keyup.native.enter.prevent="submitForm"
      >
        <v-row style="height: 60px">
          <v-col cols="12">
            <div class="form-label">Name Surname</div>
            <v-text-field
              v-model="formData.name"
              solo
              flat
              background-color="#F6F6F6"
              dense
              class="form-font"
              @focus="$helpers.selectAllText"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row style="height: 60px">
          <v-col cols="12">
            <div class="form-label">Email</div>
            <v-text-field
              disabled
              v-model="formData.email"
              solo
              flat
              background-color="#F6F6F6"
              dense
              class="form-font"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row style="height: 60px">
          <v-col cols="12">
            <div class="form-label">Country access</div>
            <country-select
              v-model="formData.countries"
              size="large"
              :full-width="true"
            />
            <!-- <v-select
                class="pkmn-input"
                v-model="formData.countries"
                :items="countriesOptions"
                value=1
                label="Country access"
              ></v-select> -->
          </v-col>
        </v-row>

        <v-row style="height: 60px">
          <v-col cols="12">
            <div class="form-label">Role</div>
            <v-select
              v-if="auth.isParkmanAdmin()"
              v-model="formData.role"
              :items="roleForSuperAdminOptions"
              solo
              flat
              background-color="#F6F6F6"
              dense
            ></v-select>
            <v-select
              v-else
              disabled
              v-model="formData.role"
              :items="roleForSuperAdminOptions"
              solo
              flat
              background-color="#F6F6F6"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row dense v-if="errorMessage">
          <v-col cols="12" class="text-right">
            <p class="pkmn-form__error" v-html="errorMessage"></p>
          </v-col>
        </v-row>

        <v-row class="form-dialog__button-wrap">
          <v-col cols="12">
            <v-btn
              class="pkmn-button pkmn-button--has-border"
              color="primary"
              @click="submit()"
            >
              Invite
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import auth from '@/auth'
import rules from '@/helpers/validation'
import CountrySelect from '@/components/Common/CountrySelect'

export default {
  name: 'FormEditAdmin',
  components: {
    CountrySelect
  },
  props: ['data', 'tmpData'],
  data() {
    return {
      alwaysOpen: true,
      auth: auth,
      formData: {},
      rules: {
        ...rules
      },
      errorMessage: null,
      roleVal: 2
    }
  },
  computed: {
    // countriesOptions () {
    //   return [{
    //     text: 'All',
    //     value: 1
    //   }, {
    //     text: 'Finland',
    //     value: 2
    //   }, {
    //     text: 'Sweden',
    //     value: 3
    //   }, {
    //     text: 'Italy',
    //     value: 4
    //   }, {
    //     text: 'Lithuania',
    //     value: 5
    //   }, {
    //     text: 'Denmark',
    //     value: 6
    //   }]
    // },
    roleForSuperAdminOptions() {
      return [
        {
          text: 'Super admin (right to accept changes)',
          value: 1
        },
        {
          text: 'Support admin',
          value: 2
        }
      ]
    },
    title() {
      return this.$t('title.edit_admin', { object: `${this.data.name}` })
    }
  },
  watch: {
    roleVal(newValue, oldValue) {
      if (newValue === 1) {
        this.formData.role = 'Super Admin'
      } else {
        this.formData.role = 'Admin'
      }
    }
  },
  methods: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    close() {
      // current data is different from data in form
      if (
        this.data.name !== this.formData.name ||
        this.data.countries !== this.formData.countries
      ) {
        this.$emit('close-without-saving', this.formData)
      } else {
        this.$emit('close')
      }
    },
    submit() {
      this.errorMessage = null

      if (!this.$refs.form.validate()) {
        return
      }

      try {
        this.$emit('submit', this.formData)
      } catch (e) {
        // done
      } finally {
        this.$emit('close')
      }
    },
    prefillData() {
      // use the unsaving data if it exists
      if (Object.keys(this.tmpData).length !== 0) {
        this.formData = Object.assign({}, this.tmpData)
      } else {
        this.formData = Object.assign({}, this.data)
      }

      if (this.auth.isParkmanAdmin()) {
        this.formData.role = 1 // default role is 'Super admin'
      } else {
        this.formData.role = 2 // default role is 'Admin'
      }
    }
  },
  mounted() {
    this.prefillData()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.form-font .v-input__slot {
  --padding: 10px;
  background: #eaeaea !important;
}
.edit-admin-form {
  // width: 339px;
  height: 100% !important;

  .v-select .v-select__selections {
    height: 48px;
    // padding: 0 12px;
  }
  .v-menu__content {
    background-color: #fff;
    .v-picker__title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media (max-width: 339px) {
    width: 100%;
  }
}
</style>
