<template>
  <v-app class="home">
    <div class="app-wrapper">
      <v-main>
        <div class="main-content">
          <slot name="content"></slot>
        </div>
      </v-main>
      <topbar ref="topbar" />
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'AdminView'
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  display: flex;
  height: 100%;
}

.main-content {
  /* flex-grow: 1; */
  height: 100%;
}
</style>
