<template>
  <div class="users__data">
    <v-data-table
      class="data__table"
      ref="table"
      :headers="headers"
      :items="data"
      fixed-header
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="mobileBreakpoint"
    >
      <template v-slot:item.countries="{ item }">
        <span>{{ toCountriesList(item) }}</span>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="mobile-row__action">
          <div>
            <v-btn
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="primary"
              @click="editUser(item)"
            >
              <img src="@/assets/button-edit.svg" />
            </v-btn>
            <v-btn
              v-if="isDeletable(item)"
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="warning"
              @click="deleteUser(item)"
            >
              <img src="@/assets/button-delete.svg" />
            </v-btn>
          </div>
        </div>
      </template>

      <template v-slot:item.role="{ item }">
        <div v-if="item.role === 1">Super Admin</div>
        <div v-else-if="item.role === 2">Admin</div>
        <div v-else>Merchant</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'DataTableSupportUsers',
  props: ['data'],
  data() {
    return {
      auth: auth,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Countries', value: 'countries' },
        { text: 'Roles', value: 'role' },
        {
          text: '',
          value: 'id',
          align: 'right',
          sortable: false,
          width: '178px'
        }
      ],
      mobileBreakpoint: 767 // screen width in pixel to switch to mobile view
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    editUser(user) {
      this.$emit('edit', user)
    },
    deleteUser(user) {
      this.$emit('delete', user)
    },
    isDeletable(item) {
      if (this.auth.isParkmanAdmin()) {
        return true
      } else if (this.auth.isMerchantAdmin() && item.role === 2) {
        return true
      } else {
        return false
      }
    },
    toCountriesList(item) {
      return item.countries.map((e) => e.country_name).join(', ')
    }
  }
}
</script>
