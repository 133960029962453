<template>
  <v-dialog
    v-model="alwaysOpen"
    width="80%"
    max-width="512px"
    @click:outside="close"
  >
    <v-card class="form-dialog form-dialog--new-user">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>
      <h1 class="form-dialog__title">
        {{ $t('title.adding_admin') }}
      </h1>
      <p class="form-dialog__subtitle">
        {{ $t('text.adding_admin') }}
      </p>

      <v-form class="add-admin-form">
        <v-row style="height: 60px">
          <v-col cols="12">
            <div class="form-label">Name Surname</div>
            <v-text-field
              v-model="formData.name"
              solo
              flat
              background-color="#F6F6F6"
              dense
              class="form-font"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row style="height: 60px">
          <v-col cols="12">
            <div class="form-label">Email</div>
            <v-text-field
              v-model="formData.email"
              solo
              flat
              background-color="#F6F6F6"
              dense
              class="form-font"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row style="height: 60px">
          <v-col cols="12">
            <!-- <v-select
            class="pkmn-input"
            v-model="formData.countries"
            :items="countriesOptions"
            value=1
            label="Country access"
          ></v-select> -->
            <div class="form-label">Countries access</div>
            <country-select
              v-if="auth.isParkmanAdmin()"
              v-model="formData.countries"
              size="large"
              :full-width="true"
            />
            <country-select
              v-if="auth.isMerchantAdmin()"
              v-model="formData.countries"
              :onlyCountry="auth.getUserCountry()"
              size="large"
              :full-width="true"
            />
          </v-col>
        </v-row>

        <v-row style="height: 60px">
          <v-col cols="12">
            <div class="form-label">Role</div>
            <v-select
              v-if="auth.isParkmanAdmin()"
              v-model="formData.role"
              :items="roleForSuperAdminOptions"
              solo
              flat
              background-color="#F6F6F6"
              dense
            ></v-select>
            <v-select
              v-else
              disabled
              v-model="formData.role"
              :items="roleForSuperAdminOptions"
              solo
              flat
              background-color="#F6F6F6"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row dense v-if="errorMessage">
          <v-col cols="12" class="text-right">
            <p class="pkmn-form__error" v-html="errorMessage"></p>
          </v-col>
        </v-row>

        <v-row class="form-dialog__button-wrap">
          <v-col cols="12">
            <v-btn
              class="pkmn-button pkmn-button--has-border"
              color="primary"
              @click="submit()"
            >
              Invite
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import auth from '@/auth'
import rules from '@/helpers/validation'
import CountrySelect from '@/components/Common/CountrySelect'

export default {
  name: 'FormAddAdmin',
  components: {
    CountrySelect
  },
  data() {
    return {
      alwaysOpen: true,
      auth: auth,
      formData: {
        role: 0,
        countries: []
      },
      rules: {
        ...rules
      },
      errorMessage: null
    }
  },
  computed: {
    countriesOptions() {
      return [
        {
          text: 'All',
          value: 1
        },
        {
          text: 'Finland',
          value: 2
        },
        {
          text: 'Sweden',
          value: 3
        },
        {
          text: 'Italy',
          value: 4
        },
        {
          text: 'Lithuania',
          value: 5
        },
        {
          text: 'Denmark',
          value: 6
        }
      ]
    },
    roleForSuperAdminOptions() {
      return [
        {
          text: 'Super admin (right to accept changes)',
          value: 1
        },
        {
          text: 'Support admin',
          value: 2
        }
      ]
    }
  },
  methods: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    close() {
      this.$emit('close')
    },
    submit() {
      this.errorMessage = null

      if (!this.$refs.form.validate()) {
        return
      }

      try {
        this.$emit('submit', this.formData)
      } catch (e) {
        // done
      } finally {
        this.$emit('close')
      }
    },
    prefillData() {
      if (this.auth.isParkmanAdmin()) {
        this.formData.role = 1 // default role is 'Super admin'
      } else {
        this.formData.role = 2 // default role is 'Admin'
      }
    }
  },
  mounted() {
    // if user press Escape, close the dialog and clear all data
    // ONLY works if a component is NOT attacted to the view as a default (use v-if to decide whether a component is attacted or not)
    // Otherwise, all 'close' listener of components existing in a view will be notified
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.close()
      }
    })

    this.prefillData()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.form-font .v-input__slot {
  --padding: 10px;
  background: #eaeaea !important;
}
.add-admin-form {
  // width: 339px;
  height: 100% !important;

  .v-select .v-select__selections {
    height: 48px;
    // padding: 0 12px;
  }
  .v-menu__content {
    background-color: #fff;
    .v-picker__title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media (max-width: 339px) {
    width: 100%;
  }
}
</style>
