<template>
  <el-select
    v-model="selectedValues"
    size="size"
    :class="{ 'full-width': fullWidth }"
    placeholder=""
    multiple
    collapse-tags
    popper-class="country-select"
    @change="onCountryChange"
  >
    <el-option-group v-for="group in countryOptions" :key="group.label">
      <el-option
        v-for="item in group.options"
        :key="item.country_id"
        :label="item.country_name"
        :value="item.country_id"
      >
      </el-option>
    </el-option-group>
  </el-select>
</template>

<script>
export default {
  name: 'CountrySelect',
  props: {
    size: {
      type: String,
      default: 'mini'
    },
    value: {
      type: Array,
      default: () => []
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    onlyCountry: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedValues: [],
      allCountryObj: {
        country_id: '_all',
        country_name: 'All countries'
      },
      clearCountryObj: {
        country_id: '_clear',
        country_name: 'Clear selections'
      },
      countries: [
        {
          country_id: '1',
          country_name: 'Finland'
        },
        {
          country_id: '2',
          country_name: 'Sweden'
        },
        {
          country_id: '3',
          country_name: 'Italy'
        },
        {
          country_id: '4',
          country_name: 'Lithuania'
        },
        {
          country_id: '5',
          country_name: 'Denmark'
        }
      ]
    }
  },
  methods: {
    /**
     * Handle country selection:
     * - If select "All countries", deselect all other countries
     * - If select other country, deselect "All countries"
     */
    onCountryChange(selectedCountries) {
      if (selectedCountries.indexOf('_clear') !== -1) {
        this.selectedValues = []
        this.$emit('clear')
      }

      if (selectedCountries.includes(this.allCountryObj.country_id)) {
        this.selectedValues = this.filteredCountries.map(
          (country) => country.country_id
        )
      }
      this.$emit('input', this.selectedValues)
    }
  },
  watch: {
    value: {
      handler(data) {
        this.selectedValues = [...data]
      },
      deep: true
    }
  },
  computed: {
    filteredCountries() {
      if (this.onlyCountry !== '') {
        return this.countries.filter((item) => {
          return item.country_id === this.onlyCountry
        })
      } else {
        return this.countries
      }
    },
    countryOptions() {
      const options = [
        {
          label: 'all',
          options: [this.allCountryObj, this.clearCountryObj]
        },
        {
          label: 'countries',
          options: this.filteredCountries
        }
      ]

      return options
    }
  },
  created() {
    this.selectedValues = this.value
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/variables';

.full-width {
  width: 100%;
}
</style>
