<template>
  <admin-template>
    <div slot="content">
      <div class="page support-users-wrapper">
        <!-- <v-row>
          <v-col cols="12" sm="9" md="5">
            <div class="o-container">
              <div class="o-row o-flex u-mt2">
                <div class="col">
                  <h2>Multiselect</h2>
                  <multi-select :options="options" @checked="onCheck"></multi-select>
                </div>
                <div class="col"><pre>{{ selected }}</pre></div>
              </div>
            </div>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="9" md="5">
            <h1 class="page__title">
              {{ $t('title.support_users') }} ({{
                this.data ? this.data.length : 0
              }})
            </h1>
            <p class="page__subtitle">
              {{ $t('text.support_users') }}
            </p>
          </v-col>

          <v-col
            cols="12"
            sm="3"
            md="5"
            offset-md="2"
            align="right"
            align-self="end"
          >
            <v-btn
              class="users__button users__button--add pkmn-button pkmn-button--full-width-mobile"
              color="primary"
              large
              @click="openAddAdminForm"
            >
              {{ $t('link.adding_user') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-divider class="pricing-divider" />

        <v-row>
          <v-col cols="12">
            <user-data-table
              @edit="openEditUserForm"
              @delete="openDeleteUserForm"
              :data="data"
            />
          </v-col>
        </v-row>

        <form-add-admin
          v-if="isAddUserFormOpening"
          @close="isAddUserFormOpening = false"
          @submit="inviteUser"
        />

        <form-edit-admin
          v-if="isEditUserFormOpening"
          :data="editingUser"
          :tmpData="tmpEditingUser"
          @submit="updateUser"
          @close="closeEditUserForm"
          @close-without-saving="openEditUserConfirmationForm"
        />

        <form-confirmation
          v-if="showEditUserConfirmation"
          ref="editUserConfirmation"
          warning
          :title="this.$t('title.unsave_confirmation')"
          :text="this.$t('text.unsave_confirmation')"
          :cancelText="this.$t('button.back')"
          :confirmText="this.$t('button.ok')"
          @close="backEditingUserForm"
          @confirm="clearEditingUser"
        />

        <form-confirmation
          v-if="showDeleteUserConfirmation"
          ref="deleteUserConfirmation"
          warning
          :title="deleteUserTitle"
          :text="this.$t('text.delete_user')"
          :cancelText="this.$t('button.back')"
          :confirmText="this.$t('button.delete')"
          @close="clearDeletingUser"
          @confirm="deleteUser"
        />

        <v-icon
          class="back-button form-dialog__button form-dialog__button--back"
          @click="goBack"
        >
          mdi-arrow-left
        </v-icon>
      </div>
    </div>
  </admin-template>
</template>

<script>
import AdminTemplate from '@/templates/Admin'
import UserDataTable from '@/components/Admin/DataTable/DataTableSupportUsers'
import FormAddAdmin from '@/components/Admin/Form/FormAddAdmin'
import FormEditAdmin from '@/components/Admin/Form/FormEditAdmin'
import FormConfirmation from '@/components/ExitConfirmDialog.vue'
// import MultiSelect from '@/components/MultipleSelect.vue'

export default {
  name: 'SupportUsers',
  components: {
    AdminTemplate,
    UserDataTable,
    FormAddAdmin,
    FormEditAdmin,
    FormConfirmation
  },
  data() {
    return {
      data: [],
      isAddUserFormOpening: false,
      isEditUserFormOpening: false,
      editingUser: {},
      tmpEditingUser: {},
      deleteUserTitle: '',
      tmpDeletingUser: {},
      showEditUserConfirmation: false,
      showDeleteUserConfirmation: false
    }
  },
  methods: {
    onCheck(val) {
      this.selected = val
    },
    openAddAdminForm() {
      this.isAddUserFormOpening = true
    },
    inviteUser(data) {
      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('text.add_user', { object: `${data.name}` })
      })
    },

    openEditUserFormWithData(data, tmpData) {
      this.editingUser = data
      this.tmpEditingUser = tmpData
      this.isEditUserFormOpening = true
    },

    openEditUserForm(user) {
      // TODO: call api to get the user

      this.openEditUserFormWithData(user, {})
    },

    closeEditUserForm() {
      this.clearEditingUser()
      this.isEditUserFormOpening = false
    },

    openEditUserConfirmationForm(tmpUser) {
      this.tmpEditingUser = tmpUser
      this.isEditUserFormOpening = false
      this.showEditUserConfirmation = true
      this.$nextTick(() => {
        this.$refs.editUserConfirmation.isOpening = true
      })
    },

    clearEditingUser() {
      this.editingUser = {}
      this.tmpEditingUser = {}
      this.showEditUserConfirmation = false
    },

    backEditingUserForm() {
      this.showEditUserConfirmation = false
      this.isEditUserFormOpening = true
    },

    updateUser(data) {
      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('title.update_admin')
      })
    },

    deleteUser() {
      this.$store.dispatch('setSystemMessage', {
        type: 'delete',
        message: this.$t('removed_confirm_message', {
          object: `${this.tmpDeletingUser.name}`
        })
      })

      this.clearDeletingUser()
    },

    clearDeletingUser() {
      this.tmpDeletingUser = {}
      this.showDeleteUserConfirmation = false
    },

    openDeleteUserForm(user) {
      this.deleteUserTitle = this.$t('title.delete_user', {
        object: `${user.name}`
      })
      this.tmpDeletingUser = user
      this.showDeleteUserConfirmation = true
      this.$nextTick(() => {
        this.$refs.deleteUserConfirmation.isOpening = true
      })
    },

    closeDeleteUserForm() {
      this.deleteUserTitle = ''
    },

    goBack() {
      this.$router.go(-1)
    },

    prefillData() {
      this.data = [
        {
          id: 1,
          name: 'Ddene Vizard',
          email: 'dvizard0@ifeng.com',
          countries: [
            {
              country_id: '1',
              country_name: 'Finland'
            },
            {
              country_id: '2',
              country_name: 'Sweden'
            }
          ],
          role: 1
        },
        {
          id: 2,
          name: 'Sherie Cuzen',
          email: 'scuzen1@sciencedirect.com',
          countries: [
            {
              country_id: '2',
              country_name: 'Sweden'
            },
            {
              country_id: '3',
              country_name: 'Italy'
            }
          ],
          role: 2
        },
        {
          id: 3,
          name: 'Corty Naul',
          email: 'cnaul2@google.com.hk',
          countries: [
            {
              country_id: '3',
              country_name: 'Italy'
            }
          ],
          role: 2
        },
        {
          id: 4,
          name: 'Sheri Turbat',
          email: 'sturbat3@tiny.cc',
          countries: [
            {
              country_id: '1',
              country_name: 'Finland'
            }
          ],
          role: 2
        },
        {
          id: 5,
          name: 'Paige Witts',
          email: 'pwitts4@homestead.com',
          countries: [
            {
              country_id: '3',
              country_name: 'Italy'
            },
            {
              country_id: '4',
              country_name: 'Lithuania'
            },
            {
              country_id: '5',
              country_name: 'Denmark'
            }
          ],
          role: 2
        },
        {
          id: 6,
          name: 'Jeanie Cullinan',
          email: 'jcullinan5@sohu.com',
          countries: [
            {
              country_id: '1',
              country_name: 'Finland'
            }
          ],
          role: 2
        },
        {
          id: 7,
          name: 'Gizela Barwick',
          email: 'gbarwick6@twitpic.com',
          countries: [
            {
              country_id: '1',
              country_name: 'Finland'
            }
          ],
          role: 2
        },
        {
          id: 8,
          name: 'Garnet Shwenn',
          email: 'gshwenn7@state.tx.us',
          countries: [
            {
              country_id: '1',
              country_name: 'Finland'
            }
          ],
          role: 2
        },
        {
          id: 9,
          name: 'Diarmid Rickcord',
          email: 'drickcord8@dion.ne.jp',
          countries: [
            {
              country_id: '1',
              country_name: 'Finland'
            }
          ],
          role: 2
        },
        {
          id: 10,
          name: 'Dora Wilstead',
          email: 'dwilstead9@cafepress.com',
          countries: [
            {
              country_id: '1',
              country_name: 'Finland'
            },
            {
              country_id: '2',
              country_name: 'Sweden'
            },
            {
              country_id: '3',
              country_name: 'Italy'
            },
            {
              country_id: '4',
              country_name: 'Lithuania'
            },
            {
              country_id: '5',
              country_name: 'Denmark'
            }
          ],
          role: 1
        }
      ]
    }
  },
  mounted() {
    this.prefillData()
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/common';
@import '~@/style/variables';

.support-users-wrapper {
  padding: $page-vertical-padding $page-horizontal-padding 0;
}

.users__button {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: block;
    width: 100%;
  }
}

.users__button--group-filter {
  margin-left: 16px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    margin-left: 0;
    margin-top: 8px;
  }
}

.back-button {
  position: absolute;
  top: 37px;
}

.page__subtitle {
  font-size: 14px;
}

.pricing-divider {
  margin-right: 173px;
}
</style>
