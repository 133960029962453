<template>
  <v-dialog
    v-model="isOpening"
    persistent
    :fullscreen="isMobile"
    width="80%"
    max-width="512"
    @keydown.esc="close"
  >
    <v-card class="confirm-dialog__content">
      <v-row class="">
        <v-col cols="12" class="text-left">
          <h1 v-html="title"></h1>
          <p class="text-left" v-html="text"></p>
        </v-col>
      </v-row>

      <v-row class="confirm-dialog__buttons">
        <v-col cols="6" class="text-left">
          <v-btn
            class="pkmn-button"
            outlined
            :color="warning ? 'danger' : 'primary'"
            @click="close"
          >
            {{ this.resetCancelText }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn
            class="pkmn-button pkmn-button--confirm"
            :color="warning ? 'danger' : 'primary'"
            @click="confirm"
          >
            {{ this.resetConfirmText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ExitConfirmDialog',
  props: {
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    cancelText: { type: String, default: '' },
    confirmText: { type: String, default: '' },
    warning: { type: Boolean, default: false }
  },
  data() {
    return {
      isOpening: false,
      mobileBreakpoint: 767
    }
  },
  computed: {
    // @todo: move this to helper
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    resetCancelText() {
      if (this.cancelText === '') {
        return this.$t('form_cancel')
      } else {
        return this.cancelText
      }
    },
    resetConfirmText() {
      if (this.confirmText === '') {
        return this.$t('g_confirm')
      } else {
        return this.confirmText
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.finally()
    },
    confirm() {
      this.$emit('confirm')
      this.finally()
    },

    /**
     * this function is always called
     */
    finally() {
      this.isOpening = false
      this.$emit('finally')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.confirm-dialog__content {
  padding: 48px 80px;
  text-align: left;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 40px 16px !important;
  }
}

.pkmn-button--confirm {
  color: #fff;
}

.confirm-dialog__buttons .pkmn-button {
  width: 100%;
}
</style>
